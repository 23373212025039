<template>
  <div class="position-relative">
    <label
      for="file-upload"
      class="custom-file-upload"
      @click="
        () => {
          $refs.uploadfile.value = '';
          $refs.uploadfile.click();
        }
      "
    >
      <div class="form-inline">
        <b-button
          variant="custom"
          type="button"
          class="btn-custom d-flex align-items-center justify-content-center"
        >
          <img src="@/assets/images/document.png" height="20" />
        </b-button>

        <input
          type="text"
          :class="[
            'input-text',
            'input-custom-upload',
            { 'error-input': isError }
          ]"
          :value="isError ? error : isMultiple ? 'เพิ่มไฟล์' : fileName"
          readonly
        />
      </div>
    </label>
    <input
      ref="uploadfile"
      id="filesDOM"
      type="file"
      class="d-none"
      :multiple="isMultiple"
      :accept="acceptHandler()"
    />
    <img
      src="@/assets/images/cancel.png"
      height="20"
      class="img-cancel"
      @click.stop="remove"
    />
  </div>
</template>

<script>
export default {
  props: {
    format: {
      required: true,
      default: "file",
      type: String
    },
    // fileName: {
    //   required: false,
    //   default: "",
    //   type: String
    // },
    required: {
      required: false,
      type: Boolean
    },
    name: {
      required: false,
      type: String
    },
    IsError: {
      required: false,
      type: Boolean
    },
    size: {
      required: false,
      default: 999,
      type: Number
    },
    fileAmount: {
      required: false,
      default: 999,
      type: Number
    },
    isMultiple: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      file: null,
      fileName: "",
      value: "",
      isError: false,
      type: {
        all: ["image/jpeg", "image/png", "application/pdf", ""],
        file: [
          "image/jpeg",
          "image/png",
          "image/tiff",
          ".heic",
          ".heif",
          ".nef",
          "application/pdf"
        ],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"]
      },
      error: ""
    };
  },
  mounted() {
    document.getElementById("filesDOM").addEventListener("change", e => {
      console.log("changed", e.target.files);
      this.handleFileChange(e);
    });
  },
  methods: {
    acceptHandler() {
      // console.log(this.type[this.format].join(", "));
      return this.type[this.format].join(", ");
    },
    handleFileChange(e) {
      console.warn("handleFileChange", e.target.files, this.isMultiple);
      if (this.isMultiple) {
        this.handleFileChangeMultiple(e);
        return;
      }
      this.isError = false;
      this.error = "";
      var _validFileExtensions = this.type.file;
      if (this.format == "file") {
        _validFileExtensions = this.type.file;
      }
      if (this.format == "image") {
        _validFileExtensions = this.type.image;
      }
      this.value = e.target.files[0];
      this.fileName = e.target.files[0].name;
      console.warn("file:::", e.target.files[0]);
      if (e.target.files.length) {
        if (_validFileExtensions.indexOf(this.value.type) < 0) {
          this.value = "";
          this.$refs.uploadfile.value = "";
          this.error = `*${this.$t("invalidFileType")}`;
          this.isError = true;
        }
      } else {
        this.value = "";
        this.$refs.uploadfile.value = "";
        this.isError = true;
      }
      this.$emit("onFileChange", this.value);
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },
    onDataChange() {},
    handleFileChangeMultiple(e) {
      console.warn("handleFileChangeMultiple", e);
      this.isError = false;
      this.error = "";
      console.warn("file Multiple:::", e.target.files);
      console.warn(this.fileAmount, this.size);
      this.value = e.target.files;
      if (e.target.files.length > this.fileAmount) {
        console.warn(`จำนวนไฟล์ไม่เกิน ${this.fileAmount} ไฟล์`);
        this.error = `จำนวนไฟล์ไม่เกิน ${this.fileAmount} ไฟล์`;
        this.isError = true;
        // console.log(this.isError, this.error);
        this.$refs.uploadfile.value = "";
        return;
      }
      var sumSize = 0;
      var fileNameList = [];
      for (let index = 0; index < this.value.length; index++) {
        sumSize += this.value[index].size;
        fileNameList.push(this.value[index].name);
      }
      this.fileName = fileNameList.join(", ");
      console.warn(sumSize, sumSize / 1024 / 1024);
      if (sumSize / 1024 / 1024 > this.size) {
        this.error = `ขนาดไม่เกิน ${this.size} MB`;
        this.isError = true;
        this.$refs.uploadfile.value = "";
        return;
      }

      if (!this.fileTypeChecker(e.target.files, this.type[this.format])) {
        this.error = `ไฟล์ไม่ถูกต้อง`;
        this.isError = true;
        this.$refs.uploadfile.value = "";
        return;
      }
      console.log(
        " this.$refs.uploadfile",
        this.$refs.uploadfile,
        JSON.parse(JSON.stringify(this.$refs.uploadfile))
      );
      this.$emit("onFileChange", [...this.value]);
    },
    fileTypeChecker(file, type) {
      for (let index = 0; index < file.length; index++) {
        if (type.indexOf(file[index].type) < 0) {
          return false;
        }
      }
      return true;
    },
    remove() {
      console.warn("remove file");
      this.value = null;
      this.fileName = null;
      this.isError = false;
      this.$emit("removeFile");
      // console.log(1234);
    }
  }
};
</script>
<style scoped>
.custom-file-upload {
  width: 100%;
}
.btn-custom {
  border-radius: 22px 0 0 22px !important;
  height: 42px !important;
}
.input-custom-upload {
  width: calc(100% - 60px) !important;
  border-radius: 0 22px 22px 0 !important;
}
.img-cancel {
  position: absolute;
  right: 10px;
  top: 10px;
}
.img-cancel:hover {
  cursor: pointer;
}
</style>
