var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[_c('label',{staticClass:"custom-file-upload",attrs:{"for":"file-upload"},on:{"click":() => {
        _vm.$refs.uploadfile.value = '';
        _vm.$refs.uploadfile.click();
      }}},[_c('div',{staticClass:"form-inline"},[_c('b-button',{staticClass:"btn-custom d-flex align-items-center justify-content-center",attrs:{"variant":"custom","type":"button"}},[_c('img',{attrs:{"src":require("@/assets/images/document.png"),"height":"20"}})]),_c('input',{class:[
          'input-text',
          'input-custom-upload',
          { 'error-input': _vm.isError }
        ],attrs:{"type":"text","readonly":""},domProps:{"value":_vm.isError ? _vm.error : _vm.isMultiple ? 'เพิ่มไฟล์' : _vm.fileName}})],1)]),_c('input',{ref:"uploadfile",staticClass:"d-none",attrs:{"id":"filesDOM","type":"file","multiple":_vm.isMultiple,"accept":_vm.acceptHandler()}}),_c('img',{staticClass:"img-cancel",attrs:{"src":require("@/assets/images/cancel.png"),"height":"20"},on:{"click":function($event){$event.stopPropagation();return _vm.remove.apply(null, arguments)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }